/* @import url("https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Roboto+Mono:ital,wght@0,400;1,300&family=Teko:wght@300&display=swap"); */

/* @import url("https://fonts.googleapis.com/css2?family=Anton&family=Caveat:wght@500;600;700&family=Kalam&family=Neuton&family=Shantell+Sans&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;600&display=swap");

body {
  /* font-family: 'Open Sans', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: "Chakra Petch", sans-serif; */
  /* font-family: "Roboto Mono", monospace; */
  /* font-family: "Teko", sans-serif; */
  font-family: "Nunito Sans", sans-serif;
  max-width: 100%;
  overflow-x: hidden;
  /* font-family: "Inter", sans-serif; */
}

.admin_custom_font {
  font-family: "Neuton", serif;
}
html::-webkit-scrollbar {
  width: 0.7vw;
}
html::-webkit-scrollbar-thumb {
  background-color: rgba(104, 79, 155, 0.7);
  border-radius: 1rem;
}
html::-webkit-scrollbar-track {
  background-color: #e2dddd;
}
html {
  scrollbar-width: 0.7vw;
  scrollbar-track-color: #ffffff;
  scrollbar-color: rgba(104, 79, 155, 0.7);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .card_clip {
    clip-path: circle(80% at 60% 10%);
  }
}
