* {
  box-sizing: border-box;
}
ol {
  list-style: decimal;
}
ul {
  list-style: disc;
}
li {
  margin: 10px 0;
}
.exam_quill_editor.quill {
  height: 300px;
  margin-bottom: 50px;
}
.ql-clipboard {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.quill .ql-toolbar {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.quill .ql-container {
  min-height: 200px;
  font-size: 14px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.swal2-container {
  z-index: 1000000 !important;
}
.inverview_Icon {
  font-size: 10px;
}
.swal2-input {
  outline: none;
}

/* #tsParticles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
} */
